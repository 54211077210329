.MainPage {
    padding-left: 10px;
}

@media only screen and (min-width: 768px) {
    .MainPage {
        padding-left: 450px;
    }
    
  }

