.about-me {
    padding-top: 125px;
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 350px;
    justify-content: center;
    gap: 15px;
    margin-top: 100px;
    color: #FF458F;
    font-size: 20px;
}

#intro {
    font-size: 50px;
    margin-top: -20px;
}

.profile-photo {
    border-radius: 50%;
    margin-bottom: 30px;
    height: 305px; 
    width: 305px; 
    display: grid;
    justify-content: center;
    padding-left: 20%;
    margin-left: -70px;
}

#sub-aboutme {
    font-family: 'EB Garamond', serif;
    text-align: center;
    padding: 10px;  
    font-size: 20px;
}

#aboutMeDescription {
    font-size: 3vmin;
    width: 350px; 
    padding: -10px; 
    margin-left: -30px;
   
}


@media only screen and (min-width: 768px) {
    .about-me  {
        display: grid;
        grid-template-rows: 600px;
        grid-template-columns: 500px 650px ;
        justify-content: center;
        gap: 80px;
        align-items: center;
        margin-left: -500px;
        margin-top: -30px;
        text-align: left;
        color: #FF458F;
    }

    #aboutMeDescription {
        font-size: 2vmin;
        width: 650px; 
        margin-left: 0;
    }
    .profile-photo {
        border-radius: 50%;
        margin-bottom: 30px;
        height: 425px; 
        width: 425px; 
    }
    #sub-aboutme {
        font-family: 'EB Garamond', serif;
        text-align: left;
        padding: 0; 
        font-size: 30px;
    }
    #intro {
        font-size: 70px;
    }
  }
