.App {
  text-align: center;
  background-size: cover;
  background-image: url('https://i.imgur.com/8V2BMxn.png');
}

.container {
  position: relative;
  width: 100%;
  height: 3500px;
  margin-bottom: 40px;
}

.image-container {
  width: 100%;
  height: 0;
  padding-top: 45.347%;
  position: sticky;
  top: 0;
  background-size: cover;
  

}

h1 {
  font-size: 70px;
  font-family: 'EB Garamond', serif;
  color: #FF458F;;
}