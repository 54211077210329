.project-section {
    display: grid;
    grid-template-columns: 410px;
    gap: 15px; 
    justify-content: center;
    padding-left: 100px;
}

#projects {
    font-size: 100px;
}

#runs:before {
    content: 'runs'  ;
}
#runs:hover:before {
    content: 'projects';
}
.project-ind {
    display: flex;
    position: relative;
    
}

.project-img { 
    width: 300px; 
    height: 51px;
    
}

.container {
    position: relative;
    width: 50%;
  }

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 300px; 
    height: 51px;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(0, 184, 170);  
}
  
.project-ind:hover .overlay {
    opacity: 1;
}
  
.text {
    color: white;
    width: 400px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }



p a {
    text-decoration: none;
    color: black;
}
@media only screen and (min-width: 768px) {
    .project-section  {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: 410px;
        grid-template-rows: 230px;
        justify-content: center;
        gap: 30px;
        margin-bottom: 70px;
        padding-left: 0;
}
    
    .project-img {
        width: 600px;
        height: 101px;
    }
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 101px;
        width: 600px;
        opacity: 0;
        transition: .5s ease;
        background-color: rgb(0, 184, 170);  
    }
    .text {
        color: white;
        width: 500px;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
      }
    
  }

