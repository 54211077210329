.resume {
    margin-left: 70px;
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
}

#seasons:before {
    content: 'seasons'  ;
}
#seasons:hover:before {
    content: 'work';
}

#resume-img { 
    width: 262px;
    height: 400px;
}
.flip-card {
    background-color: transparent;
    justify-content: center;
    width: 262px;
    height: 400px;
    perspective: 1000px; 
}
.flip-card-inner {
    position: relative;
    justify-content: center;
    width: 262px;
    height: 400px;
    text-align: center;
    transition: transform .9s;
    transform-origin: center;
    transform-style: preserve-3d;
    background-color: transparent;
}
.flip-card:hover .flip-card-inner {
    transform: rotateY(-180deg);
}

.flip-card-front {
    position: absolute;
    justify-content: center;
    width: 262px;
    height: 400px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-color: transparent;
}

.flip-card-back {
    position: absolute;
    justify-content: center;
    width: 262px;
    height: 400px;
    border-radius: 5%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-color: #FFAE90;
    color: white;
    transform: rotateY(180deg);  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}

li {
font-size: 11px    

}

h2 {
    text-align: center;
    margin-bottom: -5px;
}

h4 {
    margin-bottom: -5px;
}

.button-19 {
    appearance: button;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 150px;
    margin-bottom: -40px;
  }
  
  .button-19:after {
    background-clip: padding-box;
    background-color: #0db7aa;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  .button-19:main,
  .button-19:focus {
    user-select: auto;
  }
  
  .button-19:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }
  
  .button-19:disabled {
    cursor: auto;
  }
  
  button a {
      text-decoration: none;
      color: white; 
  }

@media only screen and (min-width: 768px) {
    .resume {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        grid-template-columns: 350px 350px;
        grid-template-rows: 600px;
        gap: 60px; 
        align-items: center;
        margin-left: 0;
    }
    .button-19 {
        width: 15%;
    }
    #resume-img { 
        width: 330px;
        height: 500px;
        
    }
    .flip-card {
        background-color: transparent;
        justify-content: center;
        width: 330px;
        height: 500px;
        perspective: 1000px; 
    }
    .flip-card-inner {
        position: relative;
        width: 330px;
        height: 500px;
        text-align: center;
        transition: transform .9s;
        transform-origin: center;
        transform-style: preserve-3d;
        background-color: transparent;
    }
    .flip-card:hover .flip-card-inner {
        transform: rotateY(-180deg);
    }
    
    .flip-card-front {
        position: absolute;
        width: 330px;
        height: 500px;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        background-color: transparent;
    }
    
    .flip-card-back {
        position: absolute;
        width: 330px;
        height: 500px;
        border-radius: 5%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        background-color: #FFAE90;
        color: white;
        transform: rotateY(180deg);  
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
    }

    li {
        font-size: 15px    
    }
   
}


