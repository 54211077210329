.contact-section {
    display: grid;
    grid-template-columns: 200px 200px;
    justify-content: center;
    text-decoration: none;
    color: #0db7aa;
    gap: 5px; 
    margin-bottom: -250px;
}

.landgh{
    display: grid;
    grid-template-columns: 200px 200px;
    margin-left: 0;
    margin-bottom: -40px;
}
.linkedin {
    margin-bottom: -40px;
}


@media only screen and (min-width: 768px) {
    /* .contact-section  {
        display: grid;
        grid-template-columns: 200px 200px 200px;
        grid-template-rows: 150px;
        justify-content: center;
        gap: 15px;
        text-decoration: none;
        color: rgb(120, 55, 34);
    } */
    .contact-section {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 200px 200px;
        grid-template-rows: 200px;
        margin-bottom: -170px;

    }
    .landgh{
        display: grid;
        grid-template-columns: 200px 200px;
        margin-left: 0;
    }
    .linkedin {
        display: grid;
        grid-template-columns: 200px 200px;
        margin-left: 0;
    }
   
  }

