.nav {
    position: fixed;
    width: 100%; 
    display: grid;
    grid-template-columns: 50% 50%;
    top: 0;
    z-index: 9999;
    padding-right: 10px;
    background-color: white;
    color: #0db7aa;
}

#logo {
    width: 200px;
    padding-top: 20px;
}
.nav-logo {
    width: 80px; 
    border: 0;
    display: inline-block;
    text-align: left;
    padding-left: 20px;
}
.nav-items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    padding-top: 70px;
    
    
}

.nav-items li {
    list-style-type: none;
    display: inline-grid; 
    font-size: 25px;
    color: #0db7aa;  
    font-family: 'EB Garamond', serif; 
}

a {
    text-decoration: none;
    color: #0db7aa;
}

nav.scrolled {
    background-color: white;
}

:target[id]:before {
    content: '';
    display: block;
    height: 100px;
    margin: -40px 0 0;
}

@media only screen and (min-width: 768px) {
    .nav-items {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        padding-top: 5px;
    }
}